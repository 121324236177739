import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Künstliche Intelligenz ist auf dem Vormarsch. Ob selbstfahrende Autos, Bilderzeugung oder Chatbots – KI wird immer mehr Teil
unseres Alltags. Der Gesprächsagent `}<a parentName="p" {...{
        "href": "https://openai.com/blog/chatgpt/"
      }}>{`ChatGPT`}</a>{` zeigt, wie hochentwickelt und menschenähnlich
KI sein kann.`}</p>
    <p>{`Viele Menschen haben bereits `}<a parentName="p" {...{
        "href": "https://mastodon.social/tags/ChatGPT"
      }}>{`Gespräche mit der KI geführt`}</a>{`. Sie beeindruckt mit
durchdachten und gut formulierten Antworten. Aber wie reagiert der Chatbot auf eher zugespitzte Fragen und vorgefasste Meinungen?
Um diese Frage zu beantworten, habe ich mich mit ChatGPT über einige weit verbreitete Barrierefreiheits-Mythen unterhalten.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHbE1ZrdEF//8QAGRAAAwADAAAAAAAAAAAAAAAAAAECERIx/9oACAEBAAEFAlk2UpXLUiJ5/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAXEAEAAwAAAAAAAAAAAAAAAAAAICEx/9oACAEBAAY/Alth/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQETGB/9oACAEBAAE/IaFg1TvwsgxI/9oADAMBAAIAAwAAABA3/wD/xAAXEQEAAwAAAAAAAAAAAAAAAAAAASFR/9oACAEDAQE/EI0p/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBh/9oACAECAQE/EMSt/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIXEQMVH/2gAIAQEAAT8QdgCbleQWsLhAmn0eDuYHcRWgn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine menschliche Hand mit ausgestrecktem Zeigefinger nähert sich einer Roboterhand an.",
          "title": "Eine menschliche Hand mit ausgestrecktem Zeigefinger nähert sich einer Roboterhand an.",
          "src": "/static/fcc689f3c7407cd695a248bde7290b31/e5166/pexels-tara-winstead-robot-and-human.jpg",
          "srcSet": ["/static/fcc689f3c7407cd695a248bde7290b31/f93b5/pexels-tara-winstead-robot-and-human.jpg 300w", "/static/fcc689f3c7407cd695a248bde7290b31/b4294/pexels-tara-winstead-robot-and-human.jpg 600w", "/static/fcc689f3c7407cd695a248bde7290b31/e5166/pexels-tara-winstead-robot-and-human.jpg 1200w", "/static/fcc689f3c7407cd695a248bde7290b31/b17f8/pexels-tara-winstead-robot-and-human.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Tara Winstead / pexels.com`}</em></p>
    <p>{`Ich habe das Gespräch auf Englisch geführt und danach vom KI-Übersetzungsdienst `}<a parentName="p" {...{
        "href": "https://www.deepl.com/"
      }}>{`Deepl.com`}</a>{` ins Deutsche
übertragen lassen (die KI gendert leider nicht). Die Unterhaltung ist in mehrere Abschnitte unterteilt, beginnend mit allgemeinen Fragen,
gefolgt von vier weit verbreiteten Mythen über Barrierefreiheit. Am Ende des Artikels werde ich meine Gedanken zu ChatGPT mit euch teilen.`}</p>
    <h2>{`Mein Gespräch mit ChatGPT`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Hallo ChatGPT! Alle reden über Barrierefreiheit im Internet. Können Sie das Konzept in einfachen Worten erklären?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Klar! Unter Barrierefreiheit im Web versteht man die Praxis, Websites und Webanwendungen für Menschen mit Behinderungen nutzbar zu machen. Dazu gehören unter anderem Menschen mit visuellen, auditiven, motorischen und kognitiven Beeinträchtigungen. Ziel der Barrierefreiheit im Internet ist es, allen Nutzern den Zugang zu den Inhalten und Funktionen einer Website zu ermöglichen, unabhängig von ihren jeweiligen Behinderungen.`}</p>
    <p>{`Dies kann erreicht werden, indem eine Reihe von Richtlinien und Standards befolgt werden, wie z. B. die Web Content Accessibility Guidelines (WCAG), die vom World Wide Web Consortium (W3C) entwickelt wurden. Diese Leitlinien enthalten eine Reihe spezifischer Kriterien, die Websites und Webanwendungen erfüllen sollten, um als zugänglich zu gelten. Durch die Einhaltung dieser Leitlinien können Website-Entwickler und -Designer sicherstellen, dass ihre Websites von einer Vielzahl von Nutzern verwendet werden können.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Ich befürchte, dass die Webentwicklung dadurch teurer und übermäßig kompliziert wird. Was ist Ihre Meinung?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Die Einbeziehung der Barrierefreiheit in die Gestaltung und Entwicklung einer Website oder Webanwendung muss nicht teuer oder übermäßig kompliziert sein. In der Tat kann die Einhaltung der Richtlinien für Barrierefreiheit im Internet oft die allgemeine Benutzerfreundlichkeit für alle Benutzer verbessern, nicht nur für Menschen mit Behinderungen. So kann beispielsweise die Verwendung klarer und anschaulicher Überschriften und Beschriftungen auf Formularen die Navigation und Nutzung der Website für alle erleichtern, nicht nur für Menschen mit Sehbehinderungen. Darüber hinaus kann die Verwendung einer geeigneten Formatierung und Struktur die Interpretation und das Lesen des Seiteninhalts durch Bildschirmlesesoftware erleichtern, was wiederum blinden oder sehbehinderten Nutzern zugute kommt.`}</p>
    <h2>{`Mythos #1: Barrierefreiheit betrifft nur eine kleine Gruppe von Nutzer:innen`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Ok, das hört sich gut an. Was sind die wichtigsten Vorteile einer barrierefrei gestalteten Website?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Eine barrierefrei gestaltete Website hat mehrere Vorteile. Für Nutzer mit Behinderungen ist der offensichtlichste Vorteil die Möglichkeit, auf die Inhalte und Funktionen der Website zuzugreifen und sie zu nutzen. Dies kann ihre allgemeine Erfahrung bei der Nutzung des Internets verbessern und es ihnen ermöglichen, an Online-Aktivitäten teilzunehmen, die ihnen vorher vielleicht nicht möglich waren.`}</p>
    <p>{`Eine barrierefreie Website ist nicht nur für Menschen mit Behinderungen von Vorteil, sondern kann auch für Unternehmen und Organisationen von Nutzen sein. Eine barrierefreie Website kann zum Beispiel die Suchmaschinenoptimierung (SEO) der Website verbessern, so dass sie für Suchmaschinen besser sichtbar wird und möglicherweise mehr Besucher auf die Website gelangen. Eine barrierefreie Website kann auch das potenzielle Publikum für die Website vergrößern, da sie von einer größeren Anzahl von Nutzern verwendet werden kann. Dies kann besonders für Unternehmen und Organisationen von Vorteil sein, die sich an eine vielfältige Bevölkerung wenden oder gesetzlich verpflichtet sind, barrierefreie Dienstleistungen anzubieten.`}</p>
    <p>{`Schließlich kann die Einbeziehung der Barrierefreiheit in die Gestaltung einer Website auch die allgemeine Benutzererfahrung für alle Benutzer verbessern, unabhängig von deren Behinderung. Dies kann zu einer höheren Kundenzufriedenheit und -treue sowie zu potenziellen Kosteneinsparungen führen, da der Bedarf an Kundensupport sinkt.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Ich bin mir nicht sicher. Ist Barrierefreiheit nicht nur für eine kleine Gruppe von Menschen von Vorteil?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Es stimmt zwar, dass die Barrierefreiheit im Internet in erster Linie Nutzern mit Behinderungen zugute kommt, aber die potenzielle Zielgruppe für barrierefreie Websites ist eigentlich recht groß. Nach Angaben der Weltgesundheitsorganisation leben etwa 15 % der Weltbevölkerung mit irgendeiner Form von Behinderung. Das bedeutet, dass die potenzielle Zielgruppe für barrierefreie Websites, gemessen an der reinen Anzahl, sehr groß ist.`}</p>
    <p>{`Darüber hinaus kann die Barrierefreiheit von Websites einer Vielzahl von Nutzern mit unterschiedlichen Behinderungen zugute kommen, z. B. mit Seh-, Hör-, Bewegungs- und kognitiven Beeinträchtigungen sowie Nutzern, die zwar keine dauerhafte, aber eine vorübergehende Beeinträchtigung (z. B. einen gebrochenen Arm) oder situationsbedingte Einschränkungen (z. B. die Nutzung einer Website auf einem mobilen Gerät von unterwegs) haben. Insgesamt ist die potenzielle Zielgruppe für barrierefreie Websites viel größer, als viele Menschen denken.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Sie sagten, dass die potenzielle Zielgruppe für barrierefreie Websites sehr groß ist. Aber nutzen Menschen mit Behinderungen das Internet wirklich so häufig?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Ja, Menschen mit Behinderungen nutzen das Internet in ähnlichem Maße wie Menschen ohne Behinderungen. Eine Studie des Pew Research Center ergab, dass in den Vereinigten Staaten etwa 88 % der Erwachsenen mit Behinderungen das Internet nutzen, verglichen mit 91 % der Erwachsenen ohne Behinderungen. Dies zeigt, dass Menschen mit Behinderungen das Internet genauso häufig nutzen wie Menschen ohne Behinderungen, und unterstreicht, wie wichtig es ist, dafür zu sorgen, dass Websites für alle Nutzer zugänglich sind.`}</p>
    <p>{`Darüber hinaus wird die Nutzung des Internets durch Menschen mit Behinderungen in den kommenden Jahren wahrscheinlich zunehmen, da Fortschritte bei unterstützenden Technologien und die Verbreitung mobiler Geräte den Internetzugang für Menschen mit Behinderungen erleichtern. Dies bedeutet, dass die potenzielle Zielgruppe für barrierefreie Websites weiter wachsen wird, so dass die Barrierefreiheit des Internets für Unternehmen und Organisationen immer wichtiger wird.`}</p>
    <h2>{`Mythos #2: Barrierefreiheit ist einfach und sollte der letzte Schritt sein`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Also gut, sagen wir, ich möchte barrierefreie Websites erstellen. Wie schwer kann das sein? Ich denke, Barrierefreiheit kann bis zum Ende des Entwicklungsprozesses warten, nachdem alle anderen Arbeiten erledigt sind.</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Es ist zwar durchaus möglich, eine Website nachträglich barrierefrei zu gestalten, aber im Allgemeinen ist es viel einfacher und effektiver, die Barrierefreiheit von Anfang an in den Design- und Entwicklungsprozess einzubeziehen. Der Grund dafür ist, dass Barrierefreiheit nicht nur eine Frage des Hinzufügens von ein paar Barrierefreiheitsmerkmalen am Ende des Entwicklungsprozesses ist, sondern dass Barrierefreiheit in jeder Phase des Design- und Entwicklungsprozesses berücksichtigt werden muss.`}</p>
    <p>{`Bei der Gestaltung des Layouts und des Inhalts einer Website ist es beispielsweise wichtig zu berücksichtigen, wie die Website von Menschen mit Behinderungen genutzt wird, und sicherzustellen, dass das Design von einer Vielzahl von Nutzern verwendet werden kann. Dies kann die Verwendung klarer und anschaulicher Überschriften und Beschriftungen, die Bereitstellung von Alternativtexten für Bilder und die Verwendung einer geeigneten Formatierung und Strukturierung beinhalten, um den Inhalt für Bildschirmlesesoftware leicht lesbar zu machen.`}</p>
    <p>{`Während des Entwicklungsprozesses ist es auch wichtig, die Website zu testen, um sicherzustellen, dass sie den Normen und Richtlinien für Barrierefreiheit entspricht. Dies kann mit einer Kombination aus automatisierten Testwerkzeugen und manuellen Tests durch Personen mit Behinderungen oder anderen Fachkenntnissen erfolgen. Wenn die Barrierefreiheit von Anfang an in den Entwurfs- und Entwicklungsprozess einbezogen wird, ist es viel wahrscheinlicher, dass die endgültige Website für alle Benutzer zugänglich ist.`}</p>
    <p>{`Insgesamt ist es zwar möglich, eine Website nachträglich zugänglich zu machen, aber es ist viel einfacher und effektiver, die Barrierefreiheit von Anfang an zu berücksichtigen. So kann sichergestellt werden, dass die Website von einem breiten Spektrum von Nutzern verwendet werden kann und den einschlägigen Normen und Richtlinien für Barrierefreiheit entspricht.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Aber die Barrierefreiheit ist so restriktiv. Würde dies nicht die Kreativität von Webdesignern und -entwicklern einschränken?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Barrierefreiheit im Web muss die Kreativität von Webdesignern und -entwicklern nicht einschränken. Tatsächlich kann die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess oft zu kreativeren und effektiveren Lösungen führen. Denn bei der Barrierefreiheit geht es nicht nur darum, einer Website ein paar Barrierefreiheitsmerkmale hinzuzufügen, sondern vielmehr darum, die Bedürfnisse aller Nutzer, einschließlich derer mit Behinderungen, zu berücksichtigen und Wege zu finden, die Website für alle nutzbar und angenehm zu machen.`}</p>
    <p>{`Bei der Gestaltung von Layout und Inhalt einer Website können Webdesigner und -entwickler beispielsweise berücksichtigen, wie die Website von Menschen mit Behinderungen genutzt wird, und nach kreativen Möglichkeiten suchen, um die Website zugänglich und nutzbar zu machen. Dazu kann die Verwendung innovativer Designtechniken gehören, wie z. B. Responsive Design, um die Website auf einer Vielzahl von Geräten zugänglich zu machen, oder die Einbeziehung von Multimedia-Elementen, wie z. B. Videos oder Audioaufnahmen, um den Inhalt für Nutzer mit Seh- oder Hörbehinderungen besser zugänglich zu machen.`}</p>
    <p>{`Außerdem können Webdesigner und -entwickler durch die Einbeziehung der Barrierefreiheit in den Entwurfs- und Entwicklungsprozess oft die allgemeine Benutzerfreundlichkeit der Website für alle Benutzer verbessern. Dies kann zu kreativeren und effektiveren Lösungen führen, die nicht nur barrierefrei, sondern auch ansprechend und angenehm für alle Nutzer sind.`}</p>
    <p>{`Insgesamt muss die Barrierefreiheit im Web die Kreativität von Webdesignern und -entwicklern nicht einschränken. Indem sie die Bedürfnisse aller Nutzer berücksichtigen und die Barrierefreiheit in den Design- und Entwicklungsprozess einbeziehen, können Webdesigner und -entwickler Websites erstellen, die nicht nur barrierefrei, sondern auch innovativ und effektiv sind.`}</p>
    <h2>{`Mythos #3: Barrierefreiheit ist zu schwierig und teuer`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Dennoch gibt es so viele verschiedene Behinderungen und unterstützende Technologien zu berücksichtigen. Die Schulung von Mitarbeitern und die Integration der Barrierefreiheit in den gesamten Entwicklungsprozess ist einfach zu teuer. Was meinen Sie dazu?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Es stimmt zwar, dass die Schulung der Mitarbeiter und die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess einige Anfangsinvestitionen erfordern, aber die langfristigen Vorteile der Barrierefreiheit im Internet können die Kosten überwiegen. Für Unternehmen und Organisationen kann eine barrierefreie Website das potenzielle Publikum für die Website vergrößern, die Suchmaschinenoptimierung (SEO) der Website verbessern und zu einer höheren Kundenzufriedenheit und -treue führen. Dies kann zu erheblichen finanziellen Vorteilen führen, z. B. zu höheren Umsätzen oder Spenden, und dazu beitragen, die anfänglichen Kosten für die Umsetzung der Barrierefreiheit im Internet auszugleichen.`}</p>
    <p>{`Darüber hinaus stehen zahlreiche Ressourcen zur Verfügung, die Unternehmen und Organisationen bei der Umsetzung der Barrierefreiheit im Internet unterstützen, darunter Schulungsmaterial, Richtlinien und Standards sowie automatische Testwerkzeuge. Diese Ressourcen können dazu beitragen, den Prozess der Umsetzung der Barrierefreiheit im Internet effizienter und kostengünstiger zu gestalten.`}</p>
    <p>{`Insgesamt kann die Umsetzung der Barrierefreiheit im Internet zwar einige Anfangsinvestitionen erfordern, aber die langfristigen Vorteile einer barrierefreien Website können die Kosten aufwiegen. Durch die Berücksichtigung der Bedürfnisse aller Nutzer und die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess können Unternehmen und Organisationen Websites erstellen, die von einer Vielzahl von Nutzern verwendet werden können und die erhebliche finanzielle Vorteile bieten.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Wäre es nicht viel billiger, einfach ein Overlay für die Barrierefreiheit zu verwenden?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Ein Accessibility-Overlay ist ein Tool, das einer bestehenden Website hinzugefügt werden kann, um sie für Nutzer mit Behinderungen besser zugänglich zu machen. Ein Accessibility-Overlay kann zwar einige Vorteile bieten, ist aber in der Regel nicht so effektiv und effizient wie die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess von Anfang an.`}</p>
    <p>{`Ein großer Nachteil der Verwendung eines Overlays für die Barrierefreiheit besteht darin, dass es sich nur auf die Zugänglichkeit der Benutzeroberfläche (UI) der Website bezieht, nicht aber auf den zugrunde liegenden Inhalt oder die Struktur. Das bedeutet, dass die Benutzeroberfläche mit einem Overlay zwar zugänglicher sein kann, der Inhalt der Website für Benutzer mit Behinderungen aber immer noch schwer oder gar nicht zugänglich ist. Ein Overlay kann zum Beispiel Bilder mit alternativem Text versehen, aber wenn die Bilder nicht richtig beschriftet oder organisiert sind, können sie für Benutzer mit Sehbehinderungen immer noch schwer zu verstehen sein.`}</p>
    <p>{`Außerdem kann die Verwendung eines Overlays für die Barrierefreiheit weniger effizient und teurer sein als die Integration der Barrierefreiheit in den Design- und Entwicklungsprozess. Dies liegt daran, dass ein Overlay für die Barrierefreiheit erst nach der Entwicklung der Website hinzugefügt werden muss, was zusätzliche Zeit und Ressourcen erfordern kann. Im Gegensatz dazu kann die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess von Anfang an effizienter und kostengünstiger sein, da die Barrierefreiheit in jeder Phase des Prozesses berücksichtigt werden muss.`}</p>
    <p>{`Insgesamt kann ein Overlay für Barrierefreiheit zwar einige Vorteile bieten, ist aber im Allgemeinen nicht so effektiv und effizient wie die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess von Anfang an. Durch die Berücksichtigung der Bedürfnisse aller Benutzer und die Einbeziehung der Barrierefreiheit in den Design- und Entwicklungsprozess können Unternehmen und Organisationen Websites erstellen, die für alle Benutzer wirklich zugänglich sind.`}</p>
    <h2>{`Mythos #4: Barrierefreie Websites sind hässlich`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Vielleicht haben Sie Recht und wir sollten von Anfang an auf Barrierefreiheit achten. Es ist einfach so schwer, barrierefreie Websites zu erstellen, die gut aussehen. Die meisten barrierefreien Websites sind hässlich und haben ein schlechtes visuelles Design.</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Es stimmt zwar, dass einige barrierefreie Websites nicht den gleichen visuellen Reiz haben wie andere Websites, aber das muss nicht der Fall sein. Barrierefreiheit und visuelle Gestaltung schließen sich nicht gegenseitig aus, und es ist möglich, Websites zu erstellen, die sowohl barrierefrei als auch ästhetisch ansprechend sind.`}</p>
    <p>{`Eine Möglichkeit, dies zu erreichen, ist die Einhaltung von Leitlinien und Standards für die Barrierefreiheit im Internet, wie z. B. die vom World Wide Web Consortium (W3C) entwickelten Web Content Accessibility Guidelines (WCAG). Diese Leitlinien enthalten spezifische Kriterien, die Websites erfüllen sollten, um als zugänglich zu gelten, lassen aber auch Flexibilität bei der Gestaltung und dem Layout der Website zu. Das bedeutet, dass Webdesigner und -entwickler ihre Kreativität und ihr Fachwissen einsetzen können, um optisch ansprechende Websites zu erstellen, die auch für Benutzer mit Behinderungen zugänglich sind.`}</p>
    <p>{`Wenn Webdesigner und -entwickler die Bedürfnisse aller Nutzer, einschließlich derer mit Behinderungen, berücksichtigen, können sie Websites erstellen, die nicht nur zugänglich und optisch ansprechend, sondern auch benutzerfreundlich und für eine Vielzahl von Nutzern ansprechend sind. Dies kann zu effektiveren und erfolgreicheren Websites führen, die von allen Nutzern geschätzt werden, unabhängig davon, ob sie eine Behinderung haben oder nicht.`}</p>
    <p>{`Auch wenn einige barrierefreie Websites nicht den gleichen visuellen Reiz haben wie andere Websites, ist es möglich, Websites zu erstellen, die sowohl barrierefrei als auch ästhetisch ansprechend sind. Indem sie die Richtlinien und Standards für Barrierefreiheit befolgen und die Bedürfnisse aller Nutzer berücksichtigen, können Webdesigner und -entwickler Websites erstellen, die zugänglich, optisch ansprechend und benutzerfreundlich sind.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Was ist mit unterstrichenen Links? Das WCAG-Erfolgskriterium 1.4.1 zwingt Sie im Grunde dazu, Links zu unterstreichen, auch wenn dies die Ästhetik einer Website beeinträchtigt.</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Das Erfolgskriterium 1.4.1 der Web Content Accessibility Guidelines (WCAG) besagt, dass „Informationen und Beziehungen, die durch die Darstellung vermittelt werden, programmatisch ermittelt werden können oder als Text verfügbar sind“. Das bedeutet, dass alle Informationen oder Beziehungen, die durch die Präsentation einer Website visuell vermittelt werden, wie z. B. die Position eines Links, auch in Textform verfügbar sein sollten, damit sie von Nutzern mit Sehbehinderungen verstanden werden können, die die visuelle Präsentation der Website möglicherweise nicht sehen können.`}</p>
    <p>{`Im Falle von Links verlangt das WCAG-Erfolgskriterium 1.4.1 nicht unbedingt, dass Links unterstrichen werden, aber es verlangt, dass die Position des Links deutlich im Text angegeben wird. Dies kann auf verschiedene Weise erreicht werden, z. B. durch die Verwendung einer anderen Farbe für Links, durch die Verwendung eines eigenen Stils oder einer eigenen Schriftart für Links oder durch Unterstreichung von Links. Die spezifische Methode zur Angabe der Position eines Links bleibt dem Webdesigner oder Entwickler überlassen, solange sie die Anforderungen des WCAG-Erfolgskriteriums 1.4.1 erfüllt.`}</p>
    <p>{`Letztendlich besteht das Ziel des WCAG-Erfolgskriteriums 1.4.1 darin, sicherzustellen, dass die Informationen und Beziehungen, die durch die Präsentation einer Website vermittelt werden, in Textform vorliegen, so dass sie von Nutzern mit Sehbehinderungen verstanden werden können. Das Unterstreichen von Links ist zwar eine Möglichkeit, dieses Kriterium zu erfüllen, aber es gibt auch andere Optionen, die ein gutes visuelles Design bieten und gleichzeitig die Zugänglichkeit gewährleisten können.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Ich:</em> Sie haben die Kriterien 1.3.1 und 1.4.1 verwechselt. Das Erfolgskriterium 1.4.1 besagt eigentlich, dass „Farbe nicht als einziges visuelles Mittel zur Übermittlung von Informationen, zur Angabe einer Aktion, zur Aufforderung einer Reaktion oder zur Unterscheidung eines visuellen Elements verwendet wird.“</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Ich entschuldige mich, wenn ich die WCAG-Erfolgskriterien verwechselt habe. Das Erfolgskriterium 1.4.1, das ich erwähnt habe, besagt in der Tat, dass „Farbe nicht als einziges visuelles Mittel zur Übermittlung von Informationen, zur Anzeige einer Aktion, zur Aufforderung einer Reaktion oder zur Unterscheidung eines visuellen Elements verwendet wird.“ Das bedeutet, dass Websites nicht ausschließlich Farbe verwenden sollten, um Informationen zu vermitteln oder eine Aktion anzuzeigen, da dies das Verständnis des Inhalts für Benutzer mit Sehbehinderungen erschweren oder unmöglich machen kann, die nicht sehen können...`}</p>
    <hr></hr>
    <h2>{`Fazit`}</h2>
    <p>{`Am Ende brach ChatGPT plötzlich mitten im Satz ab und reagierte nicht mehr. Vielleicht habe ich die künstlichen Synapsen der KI
überhitzt, wer weiß.`}</p>
    <p>{`Insgesamt bin ich sehr beeindruckt von den detaillierten und meist korrekten Antworten von ChatGPT. Wenn man mit der KI spricht,
fühlt es sich tatsächlich so an, als würde man ein Gespräch mit einem Menschen führen. Es ist fast schon unheimlich.`}</p>
    <p>{`Natürlich ist die KI nicht perfekt. Nach einiger Zeit scheinen sich gewisse Formulierungen und Aussagen zu wiederholen. Als ich
eine konkrete Frage zum `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html"
      }}>{`WCAG-Erfolgskriterium 1.4.1`}</a>{` stellte,
antwortete ChatGPT mit fehlerhaften Informationen. Ich vermute, dass diese Fehler mit mehr Training weniger werden.`}</p>
    <p>{`Habt ihr schon selbst ein Gespräch mit ChatGPT geführt? Welche Erfahrungen habt ihr gemacht?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      